.title-background {
  margin: 0;
  color: white;
  height: 100px;
  padding-bottom: 90px !important;
  padding-top: 20px;
}

.zone-upload-container {
  max-width: 1024px;
  margin-top: 20px !important;
}

#paper-upload-result {
  padding-top: 35px !important;
  padding: 25px;
}

.paper-upload {
  padding: 35px !important;
  border-radius: 10px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #ff415a;
}

.contentZoneUpload {
  border-style: dashed!important;
  border: 2px dashed #9D9D9C;
  border-color: #9D9D9C;
  border-width: 3px !important;
  -webkit-border-radius: 10px!important;
  -moz-border-radius: 10px!important;
  border-radius: 10px!important;
}

.sign-paper-source-signature {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  min-height: 321px;
  background-color: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center !important;
  justify-content: center !important;
  overflow-y: auto;
  outline: none;
}

.btnGeneral18 {
  background-color: #ff415a !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 38px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  text-transform: initial !important;
  ;
  margin-top: 14px !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneral18:disabled, button[disabled]{
  opacity: 0.19;
}

.titlePopUpForwardMail {
  text-align: center;
  margin: 14px 0px 9px!important;
  font: normal normal bold 18px/21px Muli !important;
  line-height: 30px !important;
  font-size: 22px !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  color: #e55200 !important;
  opacity: 1;
}

.opionCustom {
  width: 237px;
  white-space:normal !important;
  text-transform:unset !important;
}

.opionCustom:hover {
  background-color: #706F6F !important;
  color: #FFF !important;
}

.certificateText{
  padding-left: 4%;
  padding-top: 4px;
  color: #9D9D9C;
}

.selectCustom {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px!important;
  -moz-border-radius: 10px!important;
  max-width: 80% !important;
  padding: 0px !important;
  left: 10%;
  margin-top: 21px !important;
  opacity: 1;
}

.selectCustom div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.selectCustom .MuiInput-underline {
  margin: 0px !important;
  border: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  color: #706F6F !important;
}

.selectCustom label {
  display: inline;
}

.selectCustom label {
  margin: 0px !important;
  margin-top: -20px !important;
  padding: 0px !important;
  height: 10px !important;
}

.selectCustom option {
  width: 237px;
  max-width: 237px !important;
}

.selectCustom option:hover {
  background-color: #706F6F;
  color: #FFF;
}

.selectQR {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033;
  border: 0.8387856483459473px solid #706F6F !important;
  height: 33px !important;
  font-size: 15px !important;
  color: #9D9D9C !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px!important;
  -moz-border-radius: 10px!important;
  max-width: 82% !important;
  margin-top: 2px !important;
  opacity: 1;
}

.selectQR div {
  margin: 0px !important;
  padding: 0px !important;
  height: 33px !important;
  line-height: 2em !important;
  align-content: left !important;
  text-align: left !important;
}

.over-list {
  border-radius: 10px;
  border: 1px solid #B2B2B2;
  background-color: #FFFF;
}

.btn-close-popup {
  position: absolute;
  padding: 0px !important;
  float: right;
  top: 2px;
  right: 2px;
  width: 20px !important;
  height: 20px !important;
  display: none !important;
}

.btn-close-popup svg {
  width: 20px !important;
  height: 20px !important;
}

.dialog-container {
  width: 450px;
  max-height: 300px;
  margin: 20px;
  display: 'flex';
  flex-direction: 'row';
  overflow-y: scroll;
}

.tableDocumentSigned {
  width: 100%;
}

.headerTableDocumentSigned {
  background: #FFEFD8 0% 0% no-repeat !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px!important;
  -moz-border-radius: 10px!important;
  opacity: 1;
  text-align: center;
  font: normal normal bold 11px/16px Muli;
  letter-spacing: 0px;
  color: #E55200;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 3px !important;
}

.headerTableDocumentSigned tr{
  padding: 7px !important;
}

.headerTableDocumentSigned tr th, .headerTableDocumentSigned tr {
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
  border-collapse: collapse !important;
  max-height: 32px !important;
  box-sizing: border-box !important;
  padding: 3px !important;
}

.headerTableDocumentSigned tr {
  border-collapse: collapse !important;
  border-spacing: 0px !important;
  border: 1px #FFEFD8 !important;
}

.headerTableDocumentSigned tr th:first-child {
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.headerTableDocumentSigned tr th:last-child {
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-spacing: 0px !important;
  border: 0px !important;
}

.space-between {
  display: none !important;
}


.checkbox-label input:checked~.seleccionada {
  background-color: lawngreen;
  border-radius: 5px;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  border: 3px solid #98C500;
  transform: rotate(0deg) scale(0);
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
}

.checkbox-label input:checked~.checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  right: 6px;
  top: 2.5px;
  width: 6px;
  height: 11px;
  border: solid #98C500;
  border-width: 0 3px 3px 0;
  background-color: transparent;
}

.drag-sign {
  display: block;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.load-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 250px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #ACACAC;
  border-radius: 5px;
  opacity: 1;
}

.drag-sign img {
  box-shadow: 0px 5px 10px #68666680;
}

.drag-sign p {
  text-align: center;
  font: normal normal normal 12px/24px Muli;
  letter-spacing: 0px;
  color: #E55200;
}

.sign-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 75px;
}

.sign-container img {
  height: calc(80%);
}

.firma-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: 'auto';
  height: 47px;
}

.firma-container img {
  height: calc(100%);
  width: 100px
}

.show-loading {
  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url('../../assets/img/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

.container-sign {
  position: absolute;
  background: #EDEDED 0% 0% no-repeat padding-box;
  border-radius: 12px;
  border: 1px solid #ff415a;
  /*height: 50px;
  width: 50px;
  top: 30px;
  left: 30px;*/
}

.resizer {
  width: 12px;
  height: 12px;
  /*border-radius: 50%;
  background: white;
  border: 1px solid #878787;*/
  position: absolute;
  background: transparent url('../../assets/img/resize.png');
  border: none;
}

.resizer.nw {
  top: -2.5px;
  left: -2.5px;
  cursor: nw-resize;
}

.resizer.ne {
  top: -2.5px;
  right: -2.5px;
  cursor: ne-resize;
}

.resizer.sw {
  bottom: -2.5px;
  left: -2.5px;
  cursor: sw-resize;
}

.resizer.se {
  bottom: 2px;
  right: 2px;
  cursor: se-resize;
}

.container-sign canvas {
  height: calc(100%);
  width: calc(100%);
  /*border-radius: 12px;
  border: 2px solid #878787;*/
}

.container-sign button {
  position: absolute;
  border-radius: 50%;
  padding: 0.5em;
  width: 21px;
  height: 21px;
  /*border: 2px solid #ff415a;
  background-color: #FFFFFF;
  color: #ff415a;*/
  cursor: pointer;
  outline: none;
  background: transparent url('../../assets/img/close-sign.png') 0% 0% no-repeat padding-box;
  border: none;
  top: -10px;
  right: -10px;
}

[data-title]:hover:after {
  border-radius: 3px;
  opacity: 1;
  /*transition: all 0.1s ease 0.5s;*/
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #706f6f;
  color: #FFF;
  font: normal normal normal 11px Muli;
  /*font-size: 50%;*/
  position: absolute;
  padding: 3px 6px 3px 6px;
  top: -2.5em;
  /*bottom: -1.6em;*/
  /*left: 100%;*/
  white-space: nowrap;
  /*box-shadow: 1px 1px 3px #222222;*/
  opacity: 0;
  /*border: 1px solid #111111;*/
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.menu-tabs {
  position: relative;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF99 100%) 0% 0% no-repeat padding-box;
  background-color: #FFFFFF;
  opacity: 1;
  height: 80vh;
  width: 20%;
}

.tab-panel {
  width: 80%;
}

.Mui-selected {
  /*background: rgb(237, 237, 237);
  background: transparent linear-gradient(180deg, #DADADA00 1%, #DADADA 100%) 0% 0% no-repeat padding-box;
  border-bottom: 3px solid #E55200 !important;
  color: #E55200 !important;*/
  color: #E55200 !important;
}

.verticalTabsItems {
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  color: #6D6E71;
  background-color: #fff;
  opacity: 1;
}

.verticalTabsItems:hover {
  color: #E55200 !important;
  font-weight: bold !important;
  background-color: #fff !important;
  opacity: 1 !important;
}

.btnGeneralSign:disabled, button[disabled] {
  opacity: 0.4;
}

.btnGeneralSignIcon {
  background-color: #ff415a !important;
  margin-top: 10px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 19px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneralSign {
  background-color: #ff415a !important;
  margin-top: 10px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  ;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  text-transform: initial !important;
  ;
  float: right;
  white-space: nowrap;
  justify-content: flex-end;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneralSign:disabled, button[disabled] {
  opacity: 0.4;
}

.textZoneUpload {
  width: 100%;
  margin: 8px 0 8px 0;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.textZoneUpload span {
  color: #E55200;
  font: normal normal bold 18px/22px Muli;
  letter-spacing: 0px;
}

.btnGeneral {
  background-color: #ff415a !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

.btnGeneral:disabled, button[disabled] {
  opacity: 0.4;
}

.btnGeneralResize {
  background-color: #ff415a !important;
  margin-bottom: 49px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  padding: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
  margin-left: 5px !important;
}

.btnPopup {
  background-color: #ff415a !important;
  letter-spacing: 0.8px;
  margin-top: 7px !important;
  margin-bottom: 29px !important;
  background: #ff415a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000033 !important;
  -webkit-box-shadow: 0px 3px 0px #00000033 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  opacity: 1;
  color: #FFFFFF !important;
  text-shadow: 0px 2px 0px #00000040 !important;
  border: none !important;
  ;
  height: 40px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: initial !important;
  ;
  font-weight: bold !important;
  font-size: 18px !important;
}

.base-style-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7px !important;
  padding: 20px;
  border-width: 2;
  border-radius: 10px;
  border-color: #9D9D9C;
  border-spacing: 10px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .84s ease-in-out;
  width: 100%;
  background-color: #EDEDED;
  border: 2px dashed;
  cursor: pointer;
  box-sizing: border-box;
}

@media screen and (max-width: 505px){
  .btnGeneralSign {
    font-size: 16px !important;
    height: 47px !important;
    letter-spacing: 1px;
    border-radius: 9px !important;
    width: 219px;
  }
  .btnGeneralSignIcon {
    font-size: 16px !important;
    padding-left: 22px !important;
    height: 47px !important;
    letter-spacing: 1px;
    padding-right: 0px !important;
    border-radius: 9px !important;
    width: 219px;
  }
}

@media screen and (max-width: 765px) {
  .bodyMiddle {
    background:none;
  }
}

@media screen and (max-width: 991px) {
  .paper-upload {
    width: 100%;
    padding: 20px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .btn-close-popup {
    display: inline !important;
    background-image: url('../../assets/img/icono-cerrar.svg');
    background-position: 0 0 !important;
    background-size: 100% !important;
    background-repeat: no-repeat;
    margin: 5px !important;
    width: 24px;
    height: 26px;
  }
  .space-between {
    display: block !important;
  }
  .over-list {
    border: 0.5px solid #E3AC31 !important;
    border-radius: 5px;
  }
  .MuiBox-root, .MuiBox-root-111 {
    padding-top: 7px !important;
  }
}

@media screen and (max-width: 1250px) {
  .dialog-container {
    width: 100%;
    position: relative;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 330px;
    overflow-y: scroll;
  }
  .tableDocumentSigned {
    border-collapse: separate;
    border-spacing: 10px 0;
  }
  .tableDocumentSigned td {
    padding: 15px 0;
  }
  .tableDocumentSigned tr {
    border-bottom: 1px solid #D6D6D6;
    border-top: 1px solid #D6D6D6;
  }
  .tableDocumentSigned tr>* {
    width: 80vw;
    display: inline;
    border-bottom: none;
    text-transform: capitalize;
    padding: 10px 0 0 0;
    text-align: left;
  }
  .tableDocumentSigned tr td:first-child {
    display: inline-block;
    font-weight: bold;
    padding-bottom: 2px;
    width: 100% !important;
  }
  .headerTableDocumentSigned {
    display: none !important;
  }
}