.sign-paper {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 314px;
  min-height: 335px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #00000080;
  border: 0.4941565692424774px solid #E3AC31;
  border-radius: 14px;
  opacity: 1;
  text-align: center;
  overflow-y: auto;;
}


.dv_resultado
{
   width: 100%;
   background-color: #FFFFFF;
}
.title-sign-draw
{
  text-align: left;
  letter-spacing: 0px;
  color: #E55200;
  opacity: 1;
  font-size: 22px;
}
.button-common-orange-fy
{
    background: #F5AE19 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 2px 0px #00000040;
    opacity: 1;
    width: 90%;
    height: 45px;
}

.form-control-pop-up
{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 0px #00000033;
    border: 0.8387856483459473px solid #706F6F;
    border-radius: 10px;
    width: 100%;
    height: 33px;
    opacity: 1;
}