@import url("https://use.typekit.net/mpw5dkx.css");
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;1,600;1,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

@media(min-width: 700px) {
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: auto !important;
    }
}

@media(min-width: 250px) {
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: auto !important;
    }
}