.MuiDialog-paperWidthSm {
    width: 500px !important;
    -moz-border-radius: 12px !important;
    border-radius: 12px !important;
    background: #F6F6F6 0% 0% no-repeat padding-box !important;
}

.modalTitle {
    text-align: center;
    font-family: muli, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    color: #025BA5;
    opacity: 1;
}

.MuiButton-root {
    color: white !important;
    padding: 6px 16px !important;
    font-size: 0.875rem !important;
    min-width: 64px !important;
    box-sizing: border-box !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    font-family: Muli, Arial, sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.75;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    text-align: center !important;
    /*background: #16B1F3 0% 0% no-repeat padding-box !important;*/
}

.MuiDialogActions-root {
    flex: 0 0 auto !important;
    display: flex !important;
    padding: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 20px;
    flex-direction: column !important;
}

.contentTextField {
    display: flex;
    justify-content: center;
    align-items: center;
}

.listEmpresa {
    width: 100% !important;
    height: 360px;
}

.MuiPaper-elevation0 {
    box-shadow: none;
    background: #F6F6F6 !important;
}

.messageEnterprise {
    color: #025BA5;
    padding-top: 14px;
}

hr {
    border: 1px solid #DADADA;
    background-color: #DADADA;
}



.login {
    height: 98vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recaptcha{
    margin: 12px 9px;
}
