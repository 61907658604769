/* DoughnutChart.css */
.custom-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.custom-legend ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.custom-legend li {
  font: 12px 'Roboto';
  white-space: nowrap;
  cursor: pointer;
  color: #575756;
  display: flex;
  align-items: center;
}

.custom-legend li.striked {
  text-decoration: line-through;
}

.custom-legend li.striked span {
  background: #b3b3b3;
}

.custom-legend span {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.custom-legend li.selected {
  text-decoration: line-through;
}

.custom-legend-left {
  display: inline !important;
  gap: 10px;
  padding-top: 60px !important;
  padding-bottom: 20px !important;
  margin-right: 10px !important;
  /* min-width: 160px; */
}

.custom-legend-left li {
  margin-bottom: 10px;
}

.doughnut {
  display: flex; 
  min-Width: 450px
}

.doughnut div.canva {
  max-Width: 269px;
  max-Height: 300px;
  cursor: pointer;
}

/* .responseTimeData canvas{
  /* max-Height: 550px; 
  max-Height: 300px;
}

.responseTimeData{
  /* max-Height: 550px; 
  height: 300px;
} */

/* .transactionData {
  overflow-x: auto;
  width: 600px;
  position: relative;
}

.transactionData canvas{
  left: 0;
  top: 0;
  pointer-events: none;
  position: absolute;
} */

.transactionData {
  position: relative;
}

.transactionData > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.transactionDataArea {
  /* overflow-x: scroll; */
  height: 350px;
}

.responseTimeData {
  position: relative;
}

.responseTimeData > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.responseTimeDataArea {
  overflow-x: scroll; 
  height: 600px;
}

.chart-actions {
  margin: 16px 0
}
.chart-action {
  transition: background .25s,border-color .25s;
  background: rgba(40,44,52,.05);
  border: 1px solid transparent;
  border-radius: 6px;
  color: #3080d0;
  text-decoration: none!important;
  display: inline-block;
  font-size: .8rem;
  padding: 8px 16px;
  margin: 0 8px 8px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}