.card {
    margin: 0px 15px ;
    border-radius:10px;
    background:"#F7F7F7";
    width: 94%;
    border: "1px solid #FFB532";
  }

.MuiTypography-root{
    font-size: 10px;
}

.disabled_row{
  opacity: 0.4;
}

.dropdownIcon{
  color:#FFFFFF 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.dropdownIcon:hover{
		color: white;
    cursor: pointer;
    opacity: 1;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCCCC;
  border-radius: 5px; 
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */
